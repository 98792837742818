function Terms() {
  return (
    <div className="p-4">
      <article className="prose dark:prose-invert prose-md m-auto">
        <h1>Terms of Use</h1>
        <p>
          We do not condone the use of ElonTalks for any type of abuse, slur, or
          mistreatment of anyone. Do not engage in unlawful
          activity or attempt to impersonate any person, company, or other
          entity.
        </p>
        <p>
          Please report any abuses to{" "}
          <a href="mailto:contact@elontalks.com">us</a> @ contact@elontalks.com
        </p>
      </article>
    </div>
  );
}

export default Terms;
