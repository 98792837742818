function Paywall({ close }) {
  return (
    <div
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 bg-slate-900 bg-opacity-70 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center items-center p-0">
          <div class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all w-full max-w-[1200px]">
            <button
              onClick={close}
              type="button"
              class="absolute right-0 p-2 box-content rounded-none border-none text-slate-400 hover:text-slate-500 focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-8 w-8"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="bg-[#03071e] h-full px-2 pb-4 pt-10 sm:p-6 sm:pb-4 min-h-[550px] sm:min-h-[600px]">
              <stripe-pricing-table
                pricing-table-id="prctbl_1MtKhvLa7mNVNUAbDIRcfcjA"
                publishable-key="pk_live_51MT5HxLa7mNVNUAbLR726jFrbqBtlLwbHFSWwTQ8mP7goJH3MysznhtHdfXbnPGKbq7WhwjCVddJQWuMWvap0zfz003iafLzRx"
              ></stripe-pricing-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paywall;
