

export default function Admin() {

  return (
    <div>
      <h1 className="text-2xl font-semibold text-[#FFFFFF]">Admin</h1>
      <p className="text-xl font-semibold text-[#FFFFFF]">
        Queue size: 1
      </p>
    </div>
  );
}
