// export const HOST = "http://localhost:8080";
export const HOST = "https://api2.elontalks.com";

export const TEXT_LENGTH = 580;

export const models = [
    "elon",
    "trump",
    "kamala",
    "biden",
    "zuck",
    "obama",
    "kanye",
    "rogan",
    "cruise",
    "snoop",
]

export const modelName = {
    elon: "Elon Musk",
    zuck: "Mark Zuckerberg",
    obama: "Barack Obama",
    biden: "Joe Biden",
    trump: "Donald Trump",
    kanye: "Kanye West",
    rogan: "Joe Rogan",
    cruise: "Tom Cruise",
    snoop: "Snoop Dogg",
    kamala: "Kamala Harris",
}

export const videos = {
    elon: [
        {
            name: "Joe Rogan",
            video: "https://www.youtube.com/watch?v=ycPr5-27vSI",
          },
          {
            name: "All-In Podcast",
            video: "https://www.youtube.com/watch?v=CnxzrX9tNoc",
          },
          {
            name: "TED",
            video: "https://www.youtube.com/watch?v=zIwLWfaAg-8",
          },
    ],
    zuck: [
        {
            name: "Lex Fridman #383",
            video: "https://www.youtube.com/watch?v=Ff4fRgnuFgQ",
        },
        {
            name: "Meta",
            video: "https://www.youtube.com/watch?v=pjNI9K1D_xo",
        },
        {
            name: "NYT Events",
            video: "https://www.youtube.com/watch?v=5wk5WPONF7c",
        },
    ],
    snoop: [
        {
            name: "Windy City Live",
            video: "https://www.youtube.com/watch?v=EqLwix4FFkI",
        },
        {
            name: "Rock & Roll Hall of Fame 2017",
            video: "https://www.youtube.com/watch?v=VYFZFIZ5SBM",
        },
        {
            video: "https://www.youtube.com/watch?v=L3YWExof-eI",
            name: "Ellen"
        }
    ],
    cruise: [
        {
            name: "Mission Impossible 6 BTS",
            video: "https://www.youtube.com/watch?v=P_1TZ4gYA2s"
        },
        {
            name: "Jimmy Kimmel",
            video: "https://www.youtube.com/watch?v=KA3KDbU5dqk",
        },
        {
            name: "34th Producers Guild Awards",
            video: "https://www.youtube.com/watch?v=nRSV06_-fIY",
        },
    ],
    rogan: [
        {
            name: "JRE #1895 - Matt Walsh",
            video: "https://www.youtube.com/watch?v=I957o08voU0",
        },
        {
            name: "JRE #1954 - Bert Kreischer",
            video: "https://www.youtube.com/watch?v=GKU1bHeokEM",
        },
        {
            name: "JRE #1565 - Gary Laderman",
            video: "https://www.youtube.com/watch?v=d9LlFB4Tx-Y"
        }
    ],
    obama: [
        {
            name: "Meet the Press",
            video: "https://www.youtube.com/watch?v=l6zfLUb2mj8"
        },
        {
            name: "CNN",
            video: "https://www.youtube.com/watch?v=6C-493oPw4Q&"
        },
        {
            name: "ABC News",
            video: "https://www.youtube.com/watch?v=wnySC-twCrY"
        }
    ],
    biden: [
        {
            name: "Democratic Convention",
            video: "https://www.youtube.com/watch?v=eZ3Ssxe8vsw"
        },
        {
            name: "60 Minutes",
            video: "https://www.youtube.com/watch?v=u1UC89H4Swc",
        },
        {
            name: "White House",
            video: "https://www.youtube.com/watch?v=qhWp1nE7PO0"
        }
    ],
    trump: [
        {
            name: "ABC News",
            video: "https://www.youtube.com/watch?v=QGWRfuLZkuQ",
        },
        {
            name: "White House",
            video:  "https://www.youtube.com/watch?v=cAsUh4B19b0",
        },
        {
            name: "White House",
            video: "https://www.youtube.com/watch?v=hDNiNdsPHNA",
        },
    ],
    kanye: [
        {
            name: "JRE #1554 - Kanye West",
            video: "https://www.youtube.com/watch?v=qxOeWuAHOiw",
        },
        {
            name: "BBC Radio 1",
            video: "https://www.youtube.com/watch?v=K2T0fMkZoMo",
        },
        {
            name: "Jimmy Kimmel",
            video: "https://www.youtube.com/watch?v=rzXpJcwqYIg",
        },
    ],
    kamala: [
        {
            name: "CNN",
            video: "https://www.youtube.com/watch?v=Rus0ght1j34",
        },
        {
            name: "PBS News Hour",
            video: "https://www.youtube.com/watch?v=kAlAsFvOGSg",
        },
        {
            name: "Detroit Free Press",
            video: "https://www.youtube.com/watch?v=jUVXX8nZTZ8",
        },
    ],
};