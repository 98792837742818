import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
function Nav() {
  return (
    <div className="max-w-screen-2xl ml-auto mr-auto mb-4">
      <nav>
        <div className="">
          <div className="flex justify-between h-16 items-center flex-col sm:flex-row">
            <Link to="/" className="inline-flex items-center cursor-pointer">
              <img src={logo} alt="Elon Musk Face" className="w-12"></img>
              <p className="font-semibold text-3xl">
                <span className="text-[#6E49FF]">Elon</span>
                <span className="text-[#EEEEEE]">Talks</span>
              </p>
            </Link>
            <div className="text-right">
              {[{n: "Requests", d: "https://forms.gle/DSzLa4WeDLBfUtU66", a: true}, {n: "Terms", d: "/terms"}].map((e) => {
                return (
                  <div key={e} className="inline-flex items-center">
                    {e.a && <a href={e.d} target="_blank" rel="noreferrer" className="font-normal text-lg text-[#CBCBCB] pr-4 hover:text-[#FFFFFF]">{e.n}</a>}
                    {!e.a && <Link to={e.d} className="font-normal text-lg text-[#CBCBCB] pr-4 hover:text-[#FFFFFF]">{e.n}</Link>}
                  </div>
                );
              })}
              <a class="text-white font-bold bg-[#6E49FF] rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2" href="mailto:contact@elontalks.com">Contact us</a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
