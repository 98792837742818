function How() {
  return (
    <div className="p-4">
      <article className="prose dark:prose-invert prose-md m-auto">
        <h1>How it Works</h1>
        <p>
          This project combines two AI models: <a href="https://github.com/coqui-ai/TTS" target="_blank" rel="noreferrer">Coqui.ai TTS</a> and <a href="https://github.com/Rudrabha/Wav2Lip" target="_blank" rel="noreferrer">Wav2Lip</a>. Whenever a request is submitted, it will first generate the audio with Coqui.ai TTS and plug that into Wav2Lip to create the final video.
        </p>
        <p>
            If you have any questions, please feel free to <a href="mailto:contact@elontalks.com">contact us</a> @ contact@elontalks.com.
        </p>
      </article>
    </div>
  );
}

export default How;
