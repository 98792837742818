import { videos } from "../config";

function Videos({ modelId, setSelectedVideoIndex, selectedVideoIndex }) {
  return (
    <div className="flex flex-row flex-wrap justify-center gap-4 p-4 mb-4">
      {videos[modelId].map((e, i) => {
        return (
          <div key={e.video}>
            <video
              playsInline={true}
              onClick={() => setSelectedVideoIndex(i)}
              autoPlay={true}
              loop={true}
              muted={true}
              className={`
                  rounded-lg h-32 mb-2
                  hover:shadow-lg hover:shadow-white transform transition duration-150 hover:scale-110
                  active:scale-90 active:shadow-lg active:shadow-[#6E49FF]
                  ${
                    selectedVideoIndex === i
                      ? "shadow-lg shadow-[#6E49FF] hover:shadow-lg hover:shadow-[#6E49FF]"
                      : ""
                  }
                `}
            >
              <source src={`/${modelId}_${i + 1}.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <a
              className="text-base text-[#FFFFFF] underline hover:text-blue-600"
              rel="noreferrer"
              href={e.video}
              target="_blank"
            >
              {e.name}
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default Videos;
