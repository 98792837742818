/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { modelName, models } from "./config";
import { nameToSlug, slugToName } from "./utils";

const CelebrityPage = () => {
  const [slug, _] = useState(window.location.href.split("/")[4]);
  const modelId = models.find((e) => nameToSlug(modelName[e]) === slug);

  if (!modelId && !models.includes(slug)) {
    window.location.href = "/";
  }

  const name = modelId ? modelName[modelId] : slugToName(slug);


  // Structured Data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: `${name} AI Voice & Video Generator | ElonTalks`,
    description: `Create AI-generated videos of ${name} saying whatever you want with ElonTalks. Try our ${name} voice generator now!`,
    url: `https://ElonTalks.com/celebrity/${nameToSlug(name)}`,
    publisher: {
      "@type": "Organization",
      name: "ElonTalks",
      url: "https://ElonTalks.com",
      logo: {
        "@type": "ImageObject",
        url: `https://ElonTalks.com/${modelId}.png`,
      },
    },
  };

  return (
    <div className="min-h-screen  text-[#FFFFFF]">
      <Helmet>
        <title>{`${name} AI Voice & Video Generator | ElonTalks`}</title>
        <meta
          name="description"
          content={`Create AI-generated videos of ${name} saying whatever you want with ElonTalks. Try our ${name} voice generator now!`}
        />
        <meta
          name="keywords"
          content={`${name} AI Voice, ${name} AI Voice Generator, AI Voice Changer, AI Voice Generator, AI Celebrity Voice Generator, Retrieval-based Voice Conversion, RVC, ElonTalks`}
        />
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <header className="text-white py-6 mt-2">
        <div className="bg-[#6E49DF] p-2 flex flex-row items-center">
        <img src={`/${modelId}.png`} alt={name} className="h-24 mx-auto" />
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold">{name} AI Voice & Video Generator</h1>
          <p className="mt-2">
            <strong>ElonTalks</strong> - The platform for AI voice & video
            generation.
          </p>
        </div>
        </div>
      </header>
      <main className="container mx-auto px-4 py-8">
        {/* Introduction */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            Generate {name}'s Voice with ElonTalks AI Voice & Video Generator
          </h2>
          <p className="text-lg mb-4">
            <strong>ElonTalks</strong>, uses AI to generate realistic voices of
            celebrities like {name}. Create personalized messages, videos, or
            content featuring {name}'s voice effortlessly.
          </p>
          <Link
            to="/"
            className="inline-block bg-[#6E49FF] text-white px-6 py-3 md:px-8 md:py-4 text-base md:text-lg font-semibold rounded-full shadow-lg hover:bg-[#5D3FD3] text-center lg:w-96 w-[100%]"
            >
            Try It Now
          </Link>
        </section>

        {/* How to Make an AI Avatar Video */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">
            How to Make an AI Avatar Video
          </h2>
          <ol className="list-decimal list-inside space-y-2">
            <li>
              Start from scratch, with templates, or with one of our AI tools.
            </li>
            <li>
              Select {name} from our list of AI Avatars and choose a TTS
              language and voice.
            </li>
            <li>Enter your script into the text box.</li>
            <li>Add click-and-drop text, images, videos, and music.</li>
            <li>Click on "Generate" and your AI video will be ready!</li>
          </ol>
        </section>

        {/* Examples */}
        <section className="mb-12">
  <h2 className="text-2xl font-bold mb-4">
    Hear {name}'s Voice in Action
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div className="bg-gray-200 rounded-lg shadow-md p-6">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">Personalized Birthday Wish</h3>
      <p className="text-gray-700">
        "Hey [Name], it's {name} here! Just wanted to wish you a very
        happy birthday. Hope your day is as amazing as you are!"
      </p>
    </div>
    <div className="bg-gray-200 rounded-lg shadow-md p-6">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">Encouraging Message</h3>
      <p className="text-gray-700">
        "Hi [Name], {name} here. I heard about your upcoming exams. Just
        stay focused and believe in yourself—you've got this!"
      </p>
    </div>
    <div className="bg-gray-200 rounded-lg shadow-md p-6">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">Roast</h3>
      <p className="text-gray-700">
        "Hey [Name], it's {name}. I've seen clowns like you before, but
        I always had to pay an admission fee."
      </p>
    </div>
    <div className="bg-gray-200 rounded-lg shadow-md p-6">
      <h3 className="text-xl font-semibold mb-2 text-gray-800">Congratulatory Note</h3>
      <p className="text-gray-700">
        "Congratulations, [Name]! {name} here, just wanted to say well
        done on your recent achievement. Keep up the great work!"
      </p>
    </div>
  </div>
</section>
      </main>
    </div>
  );
};

export default CelebrityPage;
