import { Link } from "react-router-dom";
import { models, modelName } from "../config";
import { nameToSlug } from '../utils';

function Person({ modelId, setModelId }) {
  return (
    <div className="flex flex-row flex-wrap justify-center gap-4 p-4 mb-4">
      {models.map((e, i) => {
        return (
          <div key={e}>
                        <img src={`/${e}.png`} alt={modelName[e]} className={`
                  rounded-lg h-24 mb-2 mx-auto
                  hover:shadow-lg hover:shadow-white transform transition duration-150 hover:scale-110
                  active:scale-90 active:shadow-lg active:shadow-[#6E49FF]
                  ${
                    modelId === e
                      ? "shadow-lg shadow-[#6E49FF] hover:shadow-lg hover:shadow-[#6E49FF]"
                      : ""
                  } 
                `} onClick={() => {
                    setModelId(e);
                }} />
            <Link
              className="text-base text-[#FFFFFF] hover:text-[#6E49FF] active:text-[#6E49FF]"
              to={`/celebrity/${nameToSlug(modelName[e])}`}
            >
              {modelName[e]}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default Person;
