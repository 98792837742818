function Video({ setVideoId, videoId }) {
    const origin = new URL(window.location.href).origin;

  return <div className="items-center">
  <video
    playsInline={true}
    className="w-80 h-56 rounded-lg m-auto"
    src={`https://elontalks.s3.amazonaws.com/${videoId}.mp4`}
    preload="auto"
    autoplay=""
    controls="true"
    onError={() => {
      setTimeout(async () => {
        const previousVideoId = videoId;
        setVideoId("dummy");
        await new Promise((resolve) => setTimeout(resolve, 500));
        setVideoId(previousVideoId);
      }, 1000);
    }}
  ></video>
  <div className="mt-2 mb-4">
    <div className="mb-2">
    <button
        onClick={() => {navigator.clipboard.writeText(`${origin}/share/${videoId}`)}}
        className="share-btn google-plus"
      >
        Copy
      </button>
      <a
        href={`https://twitter.com/share?url=${encodeURIComponent(
          origin + "/share/" + videoId
        )}&text=Check out a video I made with ElonTalks`}
        className="share-btn twitter"
      >
        Twitter
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            origin + "/share/" + videoId
        )}`}
        className="share-btn facebook"
      >
        Facebook
      </a>
      <a
        href={`https://reddit.com/submit?url=${encodeURIComponent(
            origin + "/share/" + videoId
        )}&title=Check out a video I made with ElonTalks`}
        className="share-btn reddit"
      >
        Reddit
      </a>
    </div>
    <a
      href={`https://elontalks.s3.amazonaws.com/${videoId}.mp4`}
      download="elonTalks.mp4"
      className="
            text-sm
            p-2
            bg-emerald-700
            focus:outline-none focus:none
            rounded-full
            transform transition duration-100 hover:scale-110 active:scale-90
          "
    >
      Download
    </a>
  </div>
  <span className="text-base text-[#9c9c9c] mb-2">Videos are only kept for 14 days</span>
</div>;
}

export default Video;
