import { useState } from "react";
import Video from "./components/Video";

function Share() {
    // get the video id from the url
    const [videoId, setVideoId] = useState(window.location.href.split("/")[4]);

    return (
        <div className="p-4 text-center">
            <article className="prose dark:prose-invert prose-md m-auto">
                <h1>Shared Video</h1>
                <Video setVideoId={setVideoId} videoId={videoId}></Video>
            </article>
        </div>
    );
}

export default Share;

