// eslint-disable-next-line no-unused-vars
import react from "react";

export default function Seo() {
  return (
    <section
      className="mb-12 bg-gray-100 py-8 px-6 rounded-lg shadow-lg"
      id="ai-voice-generator"
    >
      <h2 className="text-2xl font-bold mb-4 text-[#6E49FF]">
        Make Celebrities Say Anything!
      </h2>
      <p className="text-gray-800 mb-4">
        Want to make your favorite celebrity say something funny, wish your
        friend a happy birthday, or even deliver a roast? With{" "}
        <strong>ElonTalks</strong>, you can! Our{" "}
        <strong>AI celebrity voice generator</strong> lets you type in anything,
        and in seconds, you’ll have a voice clip that sounds just like the real
        thing.
      </p>
      <p className="text-gray-800 mb-4">
        Whether you’re creating memes, funny videos, or just trying to wow your
        friends, it’s never been easier to have some fun with AI-generated
        celebrity voices. Just pick a celeb, type your message, and hit
        download.
      </p>
      <p className="text-gray-800 mb-4">
        Why settle for boring voiceovers when you can get AI voices from top
        celebs. The sky’s the limit—you can use it for pranks,
        TikTok content, or just to mess around with your buddies.
      </p>
      <p className="text-gray-800 mb-4">
        It’s fun, fast, and honestly, a little addictive. Why not give it a try?
      </p>
      <p className="text-gray-800 mt-6">
        🎙️ <strong>Step 1:</strong> Pick a celebrity voice.
      </p>
      <p className="text-gray-800">
        💬 <strong>Step 2:</strong> Type what you want them to say.
      </p>
      <p className="text-gray-800">
        📹 <strong>Step 3:</strong> Download your clip and share the laughs.
      </p>
    </section>
  );
}
